<template>
    <div>
        <v-text-field v-model="selectedPlaceSold"
                      label="Place Sold"
                      chips
                      :prepend-icon="prepend"
                      @input="updateParent()"
                      @click="showList = !showList">
        </v-text-field>

        <PlaceSoldList v-if="showList"
                       v-on:placeSoldShow="showList = $event"  
                       v-on:selectedPlaceSold="updatePlaceSold($event)"></PlaceSoldList>

    </div>
</template>

<script>
    import { isNullEmptyOrUndefined } from '../../javascript/globalFunctions'

    export default {
        components: {
            PlaceSoldList: () => import('./placeSoldList.vue')
        },
        props: ['sentPlaceSold', 'options'],
        data() {
            return {
                selectedPlaceSold: '',
                placeSold: '',
                showList: false,
                prepend: "fas fa-store"
            }
        },
        computed: {
            //placeSoldErrors() {
            //    const errors = []
            //    if (!this.$v.placeSold.$dirty) return errors
            //    !this.$v.placeSold.required && errors.push('Place Sold is required')
            //    return errors
            //},
        },
        methods: {
            checkSentPlaceSold: function () {
                if (!isNullEmptyOrUndefined(this.sentPlaceSold)) {
                    this.selectedPlaceSold = this.sentPlaceSold
                }
            },
            updateParent: function () {
                this.$emit('placeSold', this.placeSold)
            },
            updatePlaceSold: function (placeSold) {
                this.placeSold = placeSold
                this.selectedPlaceSold = this.placeSold.retailerName

                this.updateParent()
            },
            addOptions: function () {
                if (!isNullEmptyOrUndefined(this.options)) {
                    this.prepend = this.options.prepend
                }
            },
        },
        watch: {
            "sentPlaceSold": function () {
                this.checkSentPlaceSold()
            }
        },
        beforeMount() {
            this.checkSentPlaceSold()
            this.addOptions()
        },
        //validations: {
        //    placeSold: {
        //        required
        //    },
        //}
    }
</script>